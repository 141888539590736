export const globalConstants = {
  version: '3.3.80',
  downloadAppLink: 'https://play.google.com/store/apps/details?id=com.goupwards',
  // downloadAppLink: 'https://go-upwards.app.link/getapp', 
  // downloadAppLink: 'https://bit.ly/3rGYmVr',
  contactNumber: '1860-267-4777',
  address: '501-A Pinnacle Corporate Park, BKC Bandra (East), Vill Kole Kalyan,Near Trade Centre Mumbai - 400051',
  prodBaseUrl: 'https://leads.backend.upwards.in',
  // prodBaseUrl: 'http://ec2-13-234-234-182.ap-south-1.compute.amazonaws.com:8080',
  // prodBaseUrl: 'http://ec2-13-234-234-182.ap-south-1.compute.amazonaws.com:9000'
}

export const apiConstants = {
  preSignUpData: {url: '/web/v1/pre_signup/lead/'},
  socialLoginV2: { url: '/web/v2/customer/social_login/' },
  getGamingData: { url: '/web/v2/customer/playground/game/data/'},
  updateGamingResult: {url: '/web/v2/customer/playground/game/submit/'},
  leaderBoardData: {url: '/web/v2/customer/playground/leaderboard/data/'},
  playGroundConfig: {url: '/web/v2/customer/playground/config/'},
  gamingProfile: {url: '/web/v2/customer/playground/profile/data/'}
};
